import Button from '../Button/Button';
import styles from './style.module.scss';
import React, {useCallback} from 'react';
import {IErrors} from "src/helpers/interfaces";
import {IUserData} from "src/reducers/User.reducer";

interface IProps {
    resending: boolean
    code: string
    email: string | null
    onChangeCode: (code: string) => void;
    onSubmit: () => void;
    resendHandler: () => void;
    logoutHandler: () => void;
    loading: boolean;
    errors: IErrors;
    userData: IUserData | null;
}

export const ConfirmForm = ({
                                resending,
                                code,
                                email,
                                onChangeCode,
                                userData,
                                onSubmit,
                                resendHandler,
                                logoutHandler,
                                loading = false,
                                errors,
                            }: IProps) => {
    const isActive = !loading && code !== '';

    const submitCallback = useCallback<React.FormEventHandler<HTMLAnchorElement | HTMLFormElement>>(
        e => {
            e.preventDefault();
            onSubmit();
        },
        [onSubmit]
    );
    return (
        <div className={styles.authWrapper}>
            <img src='/assets/icons/key.svg' alt='Key'/>
            <h3>Confirm your email</h3>
            <form onSubmit={submitCallback}>
                <input
                    className={styles.codeInput}
                    type='text'
                    name='code'
                    autoComplete="code"
                    placeholder='Code from email'
                    autoFocus={true}
                    value={code}
                    onChange={event => onChangeCode(event.target.value)}
                />
                {errors['code'] ? (
                    <div className={styles.error} key={`error-code`}>{errors['code']}</div>
                ) : null}
                {email ? <div className={styles.emailInfo}>
                    <div>We have sent an email to the {email}</div>
                    <div><a role={"button"} onClick={resendHandler}>Resend the
                        email</a> or <a
                        role={"button"} onClick={logoutHandler}>logout</a></div>
                    {resending ? <div className={styles.success}>Sended</div> : <></>}
                </div> : <></>}
                <Button type={'submit'} variant={'primary'} disabled={!isActive}>Accept</Button>
                <input type="submit" hidden={true}/>
            </form>
        </div>
    );
};
