import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { PATH_GAME, PATH_HOME } from '../../helpers/urlList';
import Button from '../../components/Button/Button';
import { Helmet } from 'react-helmet-async';

const RulesPage = () => {
  const [token, setToken] = useState('');

  useEffect(() => {
    const auth_token = localStorage.getItem('auth_token');
    const readRules = localStorage.getItem('read_rules');
    if (!readRules || readRules === 'false') {
      document.body.style.overflow = 'unset';
      localStorage.setItem('read_rules', 'true');
    }
    auth_token && setToken(auth_token);
  }, []);
  return (
    <MainLayout headerBtnTo={PATH_HOME} verticalAlign={'flex-start'} notContainerMt sx={{ paddingTop: '3.2rem' }}>
      <Helmet>
        <title>Rules - Job in the city</title>
      </Helmet>
      <div className={styles.pageWrapper}>
        <h1>Rules</h1>
        <div className={styles.description}>
          <p>Welcome to the "Job and the city"!</p>
          <p>
            Your mission is to find all the objects highlighted in dark blue and answer the questions; there are 9 objects in
            total. Answer correctly and quickly to get to the top of the leaderboard! You will receive 1 point for each
            correct answer. There is only 1 question in each building. When answering the most recent question, you need
            to close the modal window with questions using the cross in the upper right corner of the modal window. If
            you encounter any difficulties, please email us at
            <a href="mailto:support@codenrock.com"> support@codenrock.com</a>.
          </p>
          <p>
            A prize draw will be held among the top 10 leaders: Bluetooth wireless speakers
          </p>
          <p>
            The game will end on May 10 at 12:00.
          </p>
          <p>
            The prize draw will take place on May 10 at 13:00 near the QIC digital hub stand.
          </p>
          <p>
            Good luck! 🍀
          </p>
        </div>
        {token && <Button variant={'primary'} sx={{ fontSize: '24px' }} href={PATH_GAME}>Next</Button>}
      </div>
    </MainLayout>
  );
};

export default RulesPage;
