import React, { useMemo } from 'react';
import styles from './style.module.scss';

export type TModal = {
  show: boolean,
  setShow: React.Dispatch<boolean>,
  isSecondaryStyle?: boolean,
  overlayClick?: boolean,
  pointer?: 'pointer'
}

const Modal = ({ show, setShow, children, overlayClick = false, isSecondaryStyle = false, pointer }: React.PropsWithChildren<TModal>) => {

  useMemo(() => {
    show && (document.body.style.overflow = 'hidden');
    !show && (document.body.style.overflow = 'unset');
  }, [show]);

  const className = `${pointer ? styles[pointer] : ''} ${isSecondaryStyle ? styles.modalWrapperSecondary : styles.modalWrapper}`;

  return (
    show
      ?
      <div className={className} onClick={overlayClick ? () => setShow(false) : () => {}}>
        <div className={isSecondaryStyle ? styles.modalContentSecondary : styles.modalContent} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
      :
      <React.Fragment />
  );
};

export default Modal;
