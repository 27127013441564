import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import ObjectWrapperActive, { TObjectWrapper } from '../../components/ObjectWrapperActive/ObjectWrapperActive';
import ObjectWrapperDisabled, {
  TObjectWrapperDisabled,
} from '../../components/ObjectWrapperDisabled/ObjectWrapperDisabled';
import Modal from '../../components/Modal/Modal';
import QuestionCard from '../../components/QuestionDialog';
import Header from '../../components/Header/Header';
import { IContest, IUserData } from '../../reducers/User.reducer';
import { setToken } from '../../actions/user/User.actions';
import { PATH_HOME, PATH_BOWLS, PATH_FORM } from '../../helpers/urlList';
import { INomination, ITask, ITaskList } from '../../reducers/GamePage.reducer';
import { IFormInfoData } from '../../reducers/FormPage.reducer';
import { Helmet } from 'react-helmet-async';
import Plane from '../../components/Plane/Plane';
import { ReactComponent as AbsolutBank } from '../../assets/images/objects/absolutbank.svg';
import { ReactComponent as AlfaBank } from '../../assets/images/objects/alfabank.svg';
import { ReactComponent as Boat } from '../../assets/images/objects/boat.svg';
import { ReactComponent as Codenrock } from '../../assets/images/objects/codenrock.svg';
import { ReactComponent as GolfClub } from '../../assets/images/objects/golf.svg';
import { ReactComponent as PurpleBuilding } from '../../assets/images/objects/purple.svg';
import { ReactComponent as SegwayNinebot } from '../../assets/images/objects/segway-ninebot.svg';
import { ReactComponent as Skolkovo } from '../../assets/images/objects/skolkovo.svg';
import { ReactComponent as YellowBuilding } from '../../assets/images/objects/yellow.svg';
import Button from '../../components/Button/Button';

const CONTEST_ID = process.env.REACT_APP_CONTEST_ID;
const NUMBER_OF_QUESTIONS = parseInt(process.env.REACT_APP_NUMBER_OF_QUESTIONS ?? '0');
const MUST_HAVE_NOMINATION_ID = parseInt(process.env.REACT_APP_MUST_HAVE_NOMINATION_ID ?? '0');
const FOR_2_BOWLS_NOMINATION_ID = parseInt(process.env.REACT_APP_FOR_2_BOWLS_NOMINATION_ID ?? '0');

interface IGamePage {
  token: string | null;
  userData: IUserData | null;
  getProfile: () => void;
  getContests: () => void;
  getContestsError: string | null;
  getContestsData: IContest[] | null;
  joinContest: (contest_id: number, onSuccess?: () => void) => any;
  joinContestError: string | null;
  getNominations: (contest_id: number) => any;
  getNominationsData: INomination[] | null,
  getNominationsError: string | null,
  getTasksMustHave: (contest_id: number, nomination_id: number) => any;
  getTask: (contest_id: number, nomination_id: number, task_id: number, onSuccess?: () => void) => any;
  getRandomTask: (contest_id: number, nomination_id: number, onSuccess?: (task: ITask) => void) => any;
  setFormState: (state: boolean) => void;
  formState: boolean;
  getTasksMustHaveData: ITaskList[] | null;
  getForm: (onSuccess: (data: IFormInfoData) => void) => void;
}

export const GamePage: React.FC<IGamePage> = ({
                                                token,
                                                userData,
                                                getProfile,
                                                getContests,
                                                getContestsError,
                                                getContestsData,
                                                joinContest,
                                                joinContestError,
                                                getNominations,
                                                getNominationsData,
                                                getNominationsError,
                                                getTasksMustHave,
                                                getTask,
                                                getRandomTask,
                                                getTasksMustHaveData,
                                                setFormState,
                                                getForm,
                                                formState,
                                              }: IGamePage) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isFirstPlayModal, setIsFirstPlayModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [currentTaskId, setCurrentTaskId] = useState<number | null>(null);
  // const [currentTasksId, setCurrentTasksId] = useState<number[] | null>(null);
  const [currentNominationId, setCurrentNominationId] = useState<number>();
  const navigate = useNavigate();
  const contest_id = CONTEST_ID ? Number(CONTEST_ID) : -1;
  const [rightAnswer, setRightAnswer] = useState(false);
  const mustHaveNomination = getNominationsData ? getNominationsData[0] : undefined;
  const planeRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [firstPlay, setFirstPlay] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<number>();
  const [pointer, setPointer] = useState<boolean>(false);
  const [countTheAnswer, setCountTheAnswer] = useState<number>(0);


  const getScore = (nominationsData: INomination[] | null) => {
    return nominationsData ?
      nominationsData.reduce((score, nomination) =>
        nomination.id === FOR_2_BOWLS_NOMINATION_ID && nomination.right_solutions_count > 0 ?
          score + (nomination.right_solutions_count * 2) :
          score + nomination.right_solutions_count, 0,
      ) : 0;
  };

  useEffect(() => {
    const tokenLocal = localStorage.getItem('auth_token');
    if (!tokenLocal) {
      navigate(PATH_HOME);
    } else if (!userData) {
      getProfile();
    }

    if (getContestsData) {
      const contest = getContestsData.find(contest => contest.id === contest_id);
      if (contest) {
        getNominations(contest_id);
      }
    }
  }, []);
  useEffect(() => {
    if (userData) {
      if (!userData.confirmed) {
        navigate(PATH_HOME);
      } else if (!getContestsData) {
        getContests();
      }
    }

  }, [userData, joinContest]);

  useEffect(() => {
    if (getContestsData) {
      const contest = getContestsData.find(contest => contest.id === contest_id);
      if (contest) {
        if (!getNominationsData) {
          getNominations(contest_id);
        }
      } else {
        joinContest(contest_id, () => getContests());
      }
    }
  }, [getContestsData, joinContest]);

  /* Check answer count for end game */
  useEffect(() => {
    const finishedGame = localStorage.getItem('finished_game');
    if (getNominationsData) {
      const countAnswer = getTasksMustHaveData ? getTasksMustHaveData.reduce((count, task) => count + (task.solutions.length > 0 ? 1 : 0), 0) : 0;
      console.log('countAnswer', countAnswer);
      setCountTheAnswer(countAnswer);
      if (countAnswer >= NUMBER_OF_QUESTIONS) {   // Check answer count for end game
        !finishedGame && navigate(PATH_BOWLS);
      }
    }
  }, [getNominationsData, getTasksMustHaveData]);

  useEffect(() => {
    if (getNominationsData) {
      const countAnswer2 = getTasksMustHaveData ? getTasksMustHaveData.reduce((count, task) => count + (task.solutions.length > 0 ? 1 : 0), 0) : '';
      if (countAnswer2 == 0) {
        setFirstPlay(true);
        setPointer(true);
      }
    }
  }, [getTasksMustHaveData]);

  useEffect(() => {
    if (mustHaveNomination) {
      getTasksMustHave(mustHaveNomination.contest_id, mustHaveNomination.id);
      setIsLoading(false);
    }
  }, [mustHaveNomination]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const calcLeft = (x: number): string => (width <= 428) ? `calc(100vw*${x}/428)` : `${x}px`;
  const calcTop = (y: number): string => (width <= 428) ? `calc((100vw*1096/428*${y})/1096)` : `${y}px`;
  const calcWidth = (w: number): string => (width <= 428) ? `calc(100vw/428 * ${w})` : `${w}px`;
  const calcHeight = (h: number): string => (width <= 428) ? `calc(100vw/428 * ${h})` : `${h}px`;

  const objectActiveConfigs: TObjectWrapper[] = [
    {
      key: 1,
      src: <AbsolutBank />,
      alt: 'AbsolutBank',
      left: calcLeft(303),
      top: calcTop(202),
      width: calcWidth(114),
      height: calcHeight(154),
      task_id: [5311, 5312, 5313],
      onClick: (id?: number) => {
        taskOnClick(id);
      },
    },
    {
      key: 2,
      src: <AlfaBank />,
      alt: 'AlfaBank',
      left: calcLeft(141),
      top: calcTop(0),
      width: calcWidth(101),
      height: calcHeight(293),
      task_id: [5314, 5315, 5316],
      onClick: (id?: number) => {
        taskOnClick(id);
      },
    },
    {
      key: 3,
      src: <Codenrock />,
      alt: 'Codenrock',
      left: calcLeft(189),
      top: calcTop(184),
      width: calcWidth(123),
      height: calcHeight(308),
      task_id: [5317, 5318, 5319],
      onClick: (id?: number) => {
        taskOnClick(id);
      },
    },
    {
      key: 4,
      src: <SegwayNinebot />,
      alt: 'SegwayNinebot',
      left: calcLeft(-60),
      top: calcTop(240),
      width: calcWidth(178),
      height: calcHeight(159),
      task_id: [5320, 5321],
      onClick: (id?: number) => {
        taskOnClick(id);
      },
    },
    {
      key: 9,
      src: <Skolkovo />,
      alt: 'Skolkovo',
      left: calcLeft(327),
      top: calcTop(309),
      width: calcWidth(121),
      height: calcHeight(291),
      task_id: [5323, 5324],
      onClick: (id?: number) => {
        taskOnClick(id);
      },
    },
    {
      key: 7,
      src: <PurpleBuilding />,
      alt: 'PurpleBuilding',
      left: calcLeft(182),
      top: calcTop(456),
      width: calcWidth(187),
      height: calcHeight(311),
      task_id: [5326, 5327],
      onClick: (id?: number) => {
        taskOnClick(id);
      },
    },
    {
      key: 8,
      src: <YellowBuilding />,
      alt: 'YellowBuilding',
      left: calcLeft(47),
      top: calcTop(357),
      width: calcWidth(128),
      height: calcHeight(224),
      task_id: [5308, 5309],
      onClick: (id?: number) => {
        taskOnClick(id);
      },
    },
    {
      key: 10,
      src: <GolfClub />,
      alt: 'GolfClub',
      left: calcLeft(5),
      top: calcTop(688),
      width: calcWidth(98),
      height: calcHeight(74),
      task_id: [5310, 5328],
      onClick: (id?: number) => {
        taskOnClick(id);
      },
    },
    {
      key: 11,
      src: <Boat />,
      alt: 'Boat',
      left: calcLeft(24),
      top: calcTop(982),
      width: calcWidth(102),
      height: calcHeight(104),
      task_id: [5325, 5322],
      onClick: (id?: number) => {
        taskOnClick(id);
      },
    },
  ];

  const taskOnClick = (id?: number) => {
    if (firstPlay && id) {
      setTaskId(id);
      setIsFirstPlayModal(true);
    } else if (id) {
      gameModal(id);
    }
  };

  const rulesOnClick = () => {
    setFirstPlay(false);
    setIsFirstPlayModal(false);
    if (taskId) {
      gameModal(taskId);
    }
  };

  const gameModal = (id: number) => {
    if (isLoading) return;
    setIsLoading(true);
    getTask(contest_id, MUST_HAVE_NOMINATION_ID, id, () => {
      setCurrentNominationId(MUST_HAVE_NOMINATION_ID);
      setIsModal(true);
      setIsLoading(false);
    });
  };
  const getNextTask = (task_id: number[], tasksData: ITaskList[]) => {
    const solved = tasksData.find(task => task_id.includes(task.id) && task.solutions.length > 0);
    if (solved) return;
    const tasks = tasksData.filter(task => task_id.includes(task.id) && task.solutions.length === 0);
    return tasks[Math.floor(Math.random() * tasks.length)];
  };

  const getDynamicBuilds = (
    objectConfigs: TObjectWrapper[],
    getTasksMustHaveData: ITaskList[] | null,
  ) => {
    return objectConfigs.map(item => {
      if (getTasksMustHaveData && item.task_id) {
        const task = getNextTask(item.task_id, getTasksMustHaveData);

        if (!task) {
          return <ObjectWrapperDisabled key={item.key} src={item.src} alt={item.alt} top={item.top}
                                        left={item.left} bottom={item.bottom} zIndex={item.zIndex}
                                        width={item.width} height={item.height} />;
        } else {
          return <ObjectWrapperActive key={item.key} src={item.src} alt={item.alt} top={item.top}
                                      left={item.left} right={item.right} bottom={item.bottom}
                                      onClick={() => item.onClick?.(task.id)} zIndex={item.zIndex} width={item.width}
                                      height={item.height} />;
        }
      } else return <ObjectWrapperActive key={item.key} src={item.src} alt={item.alt} top={item.top}
                                         left={item.left} bottom={item.bottom} zIndex={item.zIndex}
                                         width={item.width} height={item.height} />;
    });
  };

  const closeModal = (value: boolean) => {
    // setCurrentTaskId(null)
    // setCurrentTasksId(null)
    setIsModal(value);
  };

  useEffect(() => {
    if (rightAnswer && !isModal) {
      planeRef.current.style.setProperty('top', `${window.scrollY + (window.innerHeight / 2) + 210}px`);
      setTimeout(() => setRightAnswer(false), 10000);
    }
  }, [!isModal, rightAnswer]);

  return (
    <>
      <Helmet>
        <title>Game - Job in the city</title>
      </Helmet>
      <div className={styles.gameLayout}>
        {isLoading ? <div className={styles.preloader}></div> : <></>}
        <div className={styles.background}>
          <Header rightCount={getScore(getNominationsData)} />
          {
            getDynamicBuilds(objectActiveConfigs, getTasksMustHaveData)
          }
          {rightAnswer && !isModal &&
            <div className={styles.plane} ref={planeRef}>
              <Plane />
            </div>
          }
          <Modal show={isModal} setShow={closeModal}>
            <QuestionCard
              nomination_id={currentNominationId}
              onClose={closeModal}
              isRightAnswer={rightAnswer}
              setIsRightAnswer={setRightAnswer}
              isCountTheAnswer={countTheAnswer}
            />
          </Modal>
          <Modal show={isFirstPlayModal} setShow={setIsFirstPlayModal} isSecondaryStyle={true}>
            <div className={styles.ruleModal}>
              <h1>Every correct answer <br></br> gives you +1 point</h1>
              <h3>The faster and more accurately you answer<br></br> the questions, the higher you will be in
                the<br></br> rankings</h3><br></br>
              <div className={styles.buttonContainer}>
                <Button variant={'small'} onClick={rulesOnClick}>Play</Button>
              </div>
            </div>
          </Modal>
          <Modal show={pointer} setShow={setPointer} isSecondaryStyle={true} overlayClick={true} pointer="pointer">
            <div style={{ 'padding': ' 0px 16px 0px 16px' }}>
              <h2>Click on buildings<br></br> and other objects<br></br> to receive a quest</h2>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

const Game = React.memo(GamePage);
