import React, { useEffect, useState } from 'react';
import { LoginModal } from '../../components/LoginModal/LoginModal';
import styles from './style.module.scss';
import Button from '../../components/Button/Button';
import * as routes from '../../helpers/urlList';
import Modal from '../../components/Modal/Modal';
import { Helmet } from 'react-helmet-async';
// import AuthorizationForm from '../../components/AuthorizationForm/AuthorizationForm';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import FooterSocials from '../../components/FooterSocials/FooterSocials';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getMe } from '../../api/authAPI';
import { IUserData } from 'src/reducers/User.reducer';
import { setUserData } from 'src/actions/user/User.actions';

interface IHomePage {
  token: string | null;
  user: IUserData | null;
  setToken: (token: string | null) => void;
  setUserData: (userData: IUserData) => void;
}

export const HomePage: React.FC<IHomePage> = ({ setToken, token, user, setUserData }: IHomePage) => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const [needConfirmation, setNeedConfirmation] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const tokenSearch = searchParams.get('token');
    if (tokenSearch) {
      const readRules = localStorage.getItem('read_rules');
      if (!readRules) {
        localStorage.setItem('read_rules', 'false');
      }
      setToken(tokenSearch);
      (readRules === 'true') ? navigate(routes.PATH_GAME) : navigate(routes.PATH_RULES);
    }
  }, [navigate, searchParams, setToken]);

  const playCallback = () => {
    const tokenLocal = localStorage.getItem('auth_token');
    if (token) {
      getMe().then(res => {
        if (res.data.user) {
          setUserData(res.data.user);
          if (res.data.user.confirmed) {
            navigate(routes.PATH_GAME);
            !tokenLocal && setToken(token);
          } else {
            setNeedConfirmation(true);
          }
        } else {
          setNeedConfirmation(true);
        }
      });
    } else {
      setIsModal(true);
    }
  };

  const SignOut = () => {
    console.log('logout');
    setToken(null);
  };

  return (
    <MainLayout notContainerMt>
      <Helmet>
        <title>Codenrock - Job and the city</title>
        <meta property="og:title" content="Codenrock - Job and the city" />
        <meta property="og:description"
              content="Ваша миссия состоит в том, чтобы найти все здания, выделенные серым цветом, и ответить там на вопросы. Отвечате правильно и быстро, чтобы оказаться на вершине лидерборда!" />
        <meta property="og:image" content="/assets/codenrock_job_and_the_city.jpeg" />
      </Helmet>
      {token?<a className={styles.signOut} onClick={SignOut} role="button">Sign out</a>:""}
      <div className={styles.logoBlockWrapper}>
        <div className={styles.mainLogoWrapper}>
          <img className={styles.subLogo} src="/assets/icons/sm_tree1.svg" alt="tree1" />
          <img className={styles.buildlogo} src="/assets/icons/buildings.svg" alt="Building" />
          <img className={styles.subLogo} src="/assets/icons/sm_tree1.svg" alt="tree2" />
        </div>
        <div className={styles.subMainWrapper}>
          <h2>JOB AND THE CITY</h2>
          <p>by Codenrock</p>
        </div>
      </div>
      <div className={styles.btnGroup}>
        <Button variant={'secondary'} onClick={playCallback}>Play</Button>
        <Button variant={'secondary'} href={routes.PATH_RULES}>Rules</Button>
        <Button variant={'secondary'} href={routes.PATH_LEADERBOARD}>Leaderboard</Button>
      </div>
      <Modal show={isModal} setShow={setIsModal} overlayClick={true}>
        <LoginModal state="sign-up" />
        {/*<AuthorizationForm setToken={setToken} />*/}
      </Modal>
      <Modal show={needConfirmation} setShow={setIsModal} overlayClick={true}>
        <LoginModal state="confirm" />
        {/*<AuthorizationForm setToken={setToken} />*/}
      </Modal>
      <FooterSocials />
    </MainLayout>
  );
};
